<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 wrapper p-0">
            <!-- <app-header *ngIf="showHeader"></app-header> -->
            <!-- <router-outlet></router-outlet> -->
            <div *ngIf="showHeader" class="bottom-license mt-2 p-1 text-center">
                <img class="filter-white w-17-px" src="../../../../assets/img/copyright_black_24dp.svg">
                <p class="d-inline m-0" style="color: white;">&nbsp;&nbsp;&nbsp;&nbsp;2021 Truventor AI and
                    Robotics&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved.</p>
            </div>
        </div>
    </div>
</div>