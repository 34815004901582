<nav class="navbar navbar-expand-lg navbar-light fixed-top clsNavbar">
    <div class="clsLogo">
      <a href="#" class="navbar-brand">
        <img src="../../assets/img/Logotype-Original.svg" alt="">
        <span> Admin One </span>
      </a>
    </div>
  
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->
   
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav clsMobileHide">
        <li class="nav-item dropdown">
          <a class="nav-link" id="notificationDropdown" role="button" href="javascript:void(0);" data-toggle="dropdown" data-placement="bottom"
            title="Notification" (click)="openWhatsNewPopup()">
            <i class="fa fa-bell"></i>
          </a>

          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notificationDropdown">
            <ng-container *ngIf="notificationsList.length > 0">
              <!-- <li *ngFor="let notification of notificationsList;let i = index" [id]="notification.msgId">
                <div class="message">{{ notification.message }} <div class="new-notification-badge"
                        *ngIf="!notification.isSeen"></div>
                </div>
                <div class="time">{{ notification.createdAt | date: 'dd/MM/yyyy hh:mm a' }}</div>
              </li> -->
              <mat-menu class="notify-menu-container" xPosition="before">
                <div>
                  <mat-label class="header"><b>Notifications : </b></mat-label>
                </div>
                <div class="notify-menu" (click)="$event.stopPropagation()"
                *ngFor="let noti of notificationsList;let i=index">
                <div class="control" fxLayout="row" fxLayoutAlign="space-between start">
                  <mat-label><b>{{noti.createdAt | date:'dd MMM yyyy HH:MM'}}</b></mat-label>
                  <!-- <div>
                    <mat-icon title="Mark As Done" *ngIf="!noti.read_at" (click)="markAsRead(i)">done_all</mat-icon>
                    <mat-icon title="Delete" (click)="deleteNotify(i)" color="warn">clear</mat-icon>
                  </div> -->
                </div>
                <div class="message">{{noti.message}}</div>
              </div>
              </mat-menu>
            </ng-container>
           <ng-container *ngIf="notificationsList.length === 0">
            <span class="p-l-5">No new notification</span>
           </ng-container>
          </div>
        </li>
  
        <!-- <li class="nav-item">
          <a class="nav-link" href="#" data-toggle="tooltip" data-placement="bottom" title="Email">
            <i class="fa fa-envelope"></i>
          </a>
        </li> -->
  
        <li class="nav-item dropdown">
          <!-- <span> User </span> -->
          <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <ng-container *ngIf="!this.userSettingData.photo else profileImg">
              <img src="../../assets/img/male.png" alt="" class="clsWidth-30">
            </ng-container>
            <ng-template #profileImg>
                <img [src]="this.userSettingData.photo">
            </ng-template>
            
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <span class="p-l-5"> {{ userName }} </span>
            <a class="dropdown-item" href="javascript:void(0);" class="custom-link change-password-link" (click)="openChangePasswordDialog()">Change Password</a>
            <a class="dropdown-item" href="#" (click)="logout()"> Logout </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>