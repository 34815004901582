<!-- <ngx-ui-loader></ngx-ui-loader> -->
<div class="container p-4">
  <div class="set-pass-title">
    <p>Change Password</p>
  </div>

  <form class="form" #myForm="ngForm">
    <div class="row set-password-fields">
      <div class="col-md-12">
        <div class="input-icons" [ngClass]="{'has-error': formSubmitted && currentPassword1.invalid}">
          <div class="row">
            <div class="col-md-6 col-6">
              <label for="currPass" class="field-label">Current Password</label>
            </div>
            <div class="col-md-6 col-6 field-message text-danger text-right">
              Current password is required
            </div>
          </div>
          <span class="material-icons">lock_outline</span>
          <input type="password" id="currPass" name="oldPassword" #currentPassword1="ngModel" (keypress)="passwordKeyUP($event)"
            [(ngModel)]="changePassObj.oldPassword" placeholder="Current Password" class="form-control" required
            autocomplete="new-password">
        </div>
      </div>
    </div>

    <div class="row set-password-fields">
      <div class="col-md-12">
        <div class="input-icons"
          [ngClass]="{'has-error': formSubmitted && (password.invalid || !checkPasswordvalidation() || (changePassObj.oldPassword?.trim() && changePassObj.newPassword?.trim() && matchNewPass()) || !changePassObj.newPassword?.trim())}">
          <div class="row">
            <div class="col-md-4 col-4">
              <label for="password" class="field-label">New Password</label>
            </div>
            <div class="col-md-8 col-8 field-message text-danger text-right"
              *ngIf="formSubmitted && (password.invalid || !changePassObj.newPassword?.trim())">
              Password is required
            </div>
            <div class="col-md-8 col-8 field-message text-danger text-right"
              *ngIf="formSubmitted && (!checkPasswordvalidation() && changePassObj.newPassword)">
              Password doesn't match requirement
            </div>
          </div>
          <span class="material-icons">lock_outline</span>
          <input type="password" id="password" name="password" (keyup)="passChange()" (keypress)="passwordKeyUP($event)"
            #password="ngModel" autocomplete="new-password" [(ngModel)]="changePassObj.newPassword" placeholder="New Password"
            class="form-control" required>
            <div class="field-message text-danger text-right"
            *ngIf="changePassObj.oldPassword && changePassObj.newPassword && matchNewPass()">
            Current password and new password can't be same
          </div>
          <ng-container>
            <p class="field-label-remember-me mb-0 mt-1">Password must contain</p>
            <p class="mb-0"
              [ngClass]="{'password-validation-true': passValidArr.chrLength, 'password-validation-false': !passValidArr.chrLength}">
              Characters length should be 8-15</p>
            <p class="mb-0"
              [ngClass]="{'password-validation-true': passValidArr.oneCapital, 'password-validation-false': !passValidArr.oneCapital}">
              At least 1 uppercase letter</p>
            <p class="mb-0"
              [ngClass]="{'password-validation-true': passValidArr.oneSmall, 'password-validation-false': !passValidArr.oneSmall}">
              At least 1 lowercase letter</p>
            <p class="mb-0"
              [ngClass]="{'password-validation-true': passValidArr.oneNumber, 'password-validation-false': !passValidArr.oneNumber}">
              At least 1 number</p>
            <p class="mb-0"
              [ngClass]="{'password-validation-true': passValidArr.oneSpecial, 'password-validation-false': !passValidArr.oneSpecial}">
              At least 1 special character</p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row set-password-fields">
      <div class="col-md-12">
        <div class="input-icons"
          [ngClass]="{'has-error': formSubmitted && (confirmPassword.invalid || (changePassObj.newPassword && changePassObj.confirmPassword && !matchPass()))}">
          <div class="row">
            <div class="col-md-6 col-6">
              <label for="cpassword" class="field-label">Confirm New Password</label>
            </div>
            <div class="col-md-6 col-6 field-message text-danger text-right"
              *ngIf="formSubmitted && confirmPassword.invalid">
              Confirm New password is required
            </div>
            <div class="col-md-6 col-6 field-message text-danger text-right"
              *ngIf="changePassObj.newPassword && changePassObj.confirmPassword && !matchPass()">
              Your password doesn't match
            </div>
          </div>
          <span class="material-icons">lock_outline</span>
          <input type="password" id="cpassword" name="confirmPassword" (keypress)="passwordKeyUP($event)"
            #confirmPassword="ngModel" autocomplete="new-password" [(ngModel)]="changePassObj.confirmPassword"
            placeholder="Confirm Password" class="form-control" required>
        </div>
      </div>
    </div>

    <div class="set-password-fields set-password-btns">
      <button type="button" name="cancel" class="submit-btn-outline"
        (click)="$event.preventDefault();closeModal(false);">Cancel</button>
      <button type="button" name="go" class="submit-btn" (click)="$event.preventDefault();setNewPassword(myForm)">Save
        Changes</button>
    </div>

  </form>
</div>
