import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ChatModel } from "@models/chat";
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})

export class ChatService {

  constructor(private httpClient: HttpClient, private commonService: CommonService) { }

  getLiveOrClosedChatList(isOpen, data) {
    return this.httpClient.post(`${this.commonService.engageApiUrl()}channel/listV1?isOpen=${isOpen}`, data);
  }
  getClosedChatList() {
    return this.httpClient.get(this.commonService.engageApiUrl() + 'channel/list?isOpen=false');
  }
  createChat(data: any) {
    return this.httpClient.post(this.commonService.engageApiUrl() + 'channel/open', data);
  }

  changeChatStatus(data: any, chatID: string){
    return this.httpClient.post(this.commonService.engageApiUrl() + `chat/windowStatus?chatID=${chatID}`, data);
  };

  updateMessage(data: any, chatID: string){
    return this.httpClient.post(this.commonService.engageApiUrl() + `chat/editMessage?chatID=${chatID}`, data);
  };

  endChat(chatID: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {}
    };
    return this.httpClient.delete(this.commonService.engageApiUrl() + `channel/close?chatID=${chatID}`, options);
  }

  resumeChat(chatID: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {}
    };
    return this.httpClient.post(this.commonService.engageApiUrl() + `channel/resume?chatID=${chatID}`, options);
  }

  getRoomID = (chatID) => {
    return this.httpClient.get(this.commonService.engageApiUrl() + `channel/id?chatID=${chatID}`);
  }

  getChatDetails(chatID){
    return this.httpClient.get(this.commonService.engageApiUrl() + `channel/info?chatID=${chatID}`);
  }
  updateChat(data){
    return this.httpClient.put(this.commonService.engageApiUrl() + 'channel/update?chatID='+data.chatID, data);
  }

  getChatHistory = (chatID, lastChatId, lastBotId, chatOffset, limitValue, chatCount, botCount, botInputCount, totalbotCount, totalbotInputCount) => {
     let data = {
      "offset": chatOffset,
      "lastChatId": lastChatId,
      "lastBotId":lastBotId,
      "limitValue": limitValue,
      "chatCount": chatCount,
      "botCount": botCount,
      "botInputCount": botInputCount,
      "totalbotCount": totalbotCount,
      "totalbotInputCount": totalbotInputCount
  }


     return this.httpClient.post(this.commonService.engageApiUrl() + `chat/historyV3?chatID=${chatID}`, data);
  }

  getUpdateTypes() {
    return this.httpClient.get(this.commonService.engageApiUrl() + 'chat/updateTypes');
  }

  getMarkTags() {
    return this.httpClient.get(this.commonService.engageApiUrl() + 'chat/markTags');
  }

  getInternalRecords(chatID: string) {
    return this.httpClient.get(this.commonService.engageApiUrl() + `chat/internalRecord?chatID=${chatID}`);
  }

  getChatParticipants(chatID: string) {
    return this.httpClient.get(this.commonService.engageApiUrl() + `channel/members?chatID=${chatID}`);
  }
  
  addMember(data: any, chatID: string) {
    return this.httpClient.post(this.commonService.engageApiUrl() + `chat/share?chatID=${chatID}`, data);
  }

  deleteMember(data: any, chatID: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };
    return this.httpClient.delete(this.commonService.engageApiUrl() + `channel/remove?chatID=${chatID}`, options);
  }

  leaveChat(chatID: string){
    return this.httpClient.delete(this.commonService.engageApiUrl() + `channel/leave?chatID=${chatID}`);
  }

  markMessage(data: any, chatID:string) {
    return this.httpClient.post(this.commonService.engageApiUrl() + `chat/importantMessage?chatID=${chatID}`, data);
  }
  getStarredMessages(chatID: string) {
    return this.httpClient.get(this.commonService.engageApiUrl() + `chat/getStarredMessagesV2?chatID=${chatID}`);
  }
  sendMessage(chatID: string, data: any) {
    return this.httpClient.post(this.commonService.engageApiUrl() + `chat/adapter?chatID=${chatID}`, data);
  }
  fetchNotifications() {
    return this.httpClient.get(this.commonService.engageApiUrl() + `user/notification`,{});
  }
  markNotificationAsSeen(body) {
    return this.httpClient.put(this.commonService.engageApiUrl() + `user/notification`, body);
  }
}
