import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangePasswordModal, IPassValidArr } from '@models/auth';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastDisplay } from 'src/app/shared/toastr/toast-service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy {

  public formSubmitted: boolean = false;
  public passValidArr: IPassValidArr = new IPassValidArr();
  public changePassObj:ChangePasswordModal = new ChangePasswordModal();

  private unsubscribe$ = new Subject<void>();
  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private toastr: ToastDisplay,
    private loading: NgxUiLoaderService,
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
  }

  closeModal(response){
    this.activeModal.close(response);
  }

  matchPass(): boolean {
    return this.changePassObj.newPassword === this.changePassObj.confirmPassword;
  }

  matchNewPass(): boolean {
    return this.changePassObj.oldPassword === this.changePassObj.newPassword;
  }

  setNewPassword(myForm){
    this.formSubmitted = true;
    if(myForm.valid && this.matchPass() && !this.matchNewPass()){
      this.loading.start();
      this.authService.changePasswordPost(this.changePassObj).pipe(takeUntil(this.unsubscribe$)).subscribe(
        res=>{
          if(res['status'] == "success"){
            this.toastr.showSuccess(res['message']);
            this.closeModal(true);
          }
          this.loading.stop();
        }, err=>{
          this.loading.stop();
        }
      )
    }
  }

  passChange() {
    this.passValidArr.chrLength =
      this.changePassObj.newPassword && this.changePassObj.newPassword.length > 7
      && this.changePassObj.newPassword && this.changePassObj.newPassword.length < 16
        ? true
        : false;
    this.passValidArr.oneCapital =
      this.changePassObj.newPassword && /[A-Z]/.test(this.changePassObj.newPassword)
        ? true
        : false;
    this.passValidArr.oneSmall =
      this.changePassObj.newPassword && /[a-z]/.test(this.changePassObj.newPassword)
        ? true
        : false;
    this.passValidArr.oneNumber =
      this.changePassObj.newPassword && /\d/.test(this.changePassObj.newPassword)
        ? true
        : false;
    this.passValidArr.oneSpecial =
      this.changePassObj.newPassword && /[!@#$%^&*]/.test(this.changePassObj.newPassword)
        ? true
        : false;
  }

  checkPasswordvalidation(){
    return Object.keys(this.passValidArr).filter(f => this.passValidArr[f]).length == 5 && this.changePassObj.newPassword
  }

  passwordKeyUP(event){
    if(event.which == 32) return false;
    return true;
  }

}
