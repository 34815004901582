import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@common-components/page-not-found/page-not-found.component';
import { BidsAuthGuard } from '@guards/bids.guard';
import { CustomerOrderAuthGuard } from '@guards/customer-order.guard';
import { DashboardAuthGuard } from '@guards/dashboard.guard';
import { EnquiryAuthGuard } from '@guards/enquiry.guard';
import { InvoiceAuthGuard } from '@guards/invoice.guard';
import { PartnerOrderAuthGuard } from '@guards/partner-orders.guard';
import { QuotationAuthGuard } from '@guards/quotation.guard';
import { LoginAuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: "auth",
  },
  {
    path: "auth",
    loadChildren: () => import("@modules/auth/auth.module").then((m) => m.AuthModule),
    canLoad: [LoginAuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () => import("@modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canLoad: [DashboardAuthGuard]
  },
  {
    path: 'bids',
    loadChildren: () => import("@modules/bids/bids.module").then((m) => m.BidsModule),
    canLoad: [BidsAuthGuard],
    data: {key:'bids'}
  }, 
  {
    path: 'enquiry',
    loadChildren: () => import("@modules/enquiry/enquiry.module").then((m) => m.EnquiryModule),
    canLoad: [EnquiryAuthGuard],
    data: {key:'enquiry'}
  },
  {
    path: 'customer',
    loadChildren: () => import("@modules/customer_order/customer.module").then((m) => m.CustomerModule),
    canActivate: [CustomerOrderAuthGuard],
    data:{key:'customer'}
  },
  {
    path: 'partner',
    loadChildren: () => import("@modules/partner/partner.module").then((m) => m.PartnerModule),
    canActivate: [PartnerOrderAuthGuard],
    data:{key:'partner'}
  },
  {
    path: 'invoice',
    loadChildren: () => import("@modules/invoice/invoice.module").then((m) => m.InvoiceModule),
    canActivate: [InvoiceAuthGuard],
    data:{key:'invoice'}
  },
  {
    path: 'orders',
    loadChildren: () => import("@modules/partner_orders/partner_order.module").then((m) => m.PartnerOrderModule),
    canActivate: [PartnerOrderAuthGuard],
    data:{key:'orders'}
  },
  {
    path: 'quotation',
    loadChildren: () => import("@modules/quotation/quotation.module").then((m) => m.QuotationModule),
    canActivate: [QuotationAuthGuard],
    data:{key:'quotation'}
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'page-404',
  },
  {
    path: 'page-404',
    component: PageNotFoundComponent,
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
