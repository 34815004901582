import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NotificationModel } from '@models/notification';
import { UserSettings } from '@models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { ChatService } from '@services/dashboard/chat.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastDisplay } from 'src/app/shared/toastr/toast-service';
import { UtilsHelper } from 'src/app/shared/utils.helper';
import { sortBy as _sortBy } from 'lodash';
import { UploadFilesService } from '@services/upload-files.service';
import { CommonService } from '@services/common.service';
import { UserProfileModalComponent } from '@common-components/user-profile-modal/user-profile.component';
import { ChangePasswordModalComponent } from '@modules/auth/change-password-modal/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  openWhatsNew: boolean = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private _chatService: ChatService,
    private _authService: AuthService,
    private _toastr: ToastDisplay,
    private modelService: NgbModal,
    private router: Router,
    private uploadFileService: UploadFilesService,
    private commonService: CommonService,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
       // console.log("navigation start");
        this.showSettings = false;
        this.userMenuOpen = false;
        this.showNotifications = false;
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

      }
      if (event instanceof NavigationError) {
        // Hide loading indicator

        this.showSettings = false;
        this.userMenuOpen = false;
        this.showNotifications = false;
        // Present error to user
       // console.log(event.error);
      }
    });
  }
  ngOnDestroy(): void {
    UtilsHelper.removeObject("orgName");
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  userName: string;
  public notificationsList: NotificationModel[] = [];
  public showNotifications: boolean = false; //show/hide notifications panel
  public showSettings: boolean = false;
  public newNotifications: number = 0; //new notifications count
  public notificationsRead: boolean = false; //notifications read or not
  public userMenuOpen: boolean = false;
  public userSettingData: UserSettings = new UserSettings();
  public sidebar:boolean = false;
  public showProfileMobile:boolean = false;
  public changePasswordMobile:boolean = false;
  public decodedToken:any;

  ngOnInit(): void {
    this.userName = UtilsHelper.getUserName();
    this.fetchNotifications();
    // this.fetchUserProfile();
    this.decodedToken = UtilsHelper.getDecodedToken();
    setInterval(() => {
     this.fetchNotifications();
    }, 15000);

    this.commonService.profileImageUrl$.subscribe(res=> {
      this.userSettingData.photo = res;
    });
    this.commonService.profileName$.subscribe(res=> {
      if(res)
        this.userName = res;
    });
    this.commonService.hidePopups.subscribe(res => {
      //hide all popups when clicked outside this component
      if(res!=="header-component"){//prevent event fired from same component
      this.showSettings = false;
      this.showNotifications = false;
      this.userMenuOpen = false;
      }
    });
  }

  goToHome = () => {
    if (UtilsHelper.IsSuperAdmin) {
      this.router.navigate(['/dashboard/organizations']);
    }
    else {
      this.router.navigate(['/dashboard/organizations']);
    }
  }

  manageRoles = () => {
    this.sidebar = false;
    this.router.navigate(['/dashboard/users']);
  }

  closeProfileHandler = () => {
    this.showProfileMobile = false;
  }

  closeChangePasswordHandler = () => {
    this.changePasswordMobile = false;
  }

  logout = () => {
      if(UtilsHelper.getAuthToken()){
        this._authService.logoutAPI().subscribe(
          res=>{
            UtilsHelper.clearAll();
            UtilsHelper.setObject("logout", true);
            window.location.reload();
          }, err=>{
            UtilsHelper.clearAll();
            UtilsHelper.setObject("logout", true);
            window.location.reload();
          }
        )
    }
  };

  fetchNotifications = () => {
    this._chatService
      .fetchNotifications()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          console.log(res);
          if (res['status'] == 'success') {
            let data = res['data'][0];
            if (!data) {
              data = [];
            }
            if (data && data.length > 0) {
              data = data.sort();
            }
            this.newNotifications = data.filter(f => !f.isSeen).length;
            this.notificationsList = data;
          }
          //this.loader.stop();
        },
        (error) => {
          //this.loader.stop();
        }
      );
  };

  openCloseNotification(){
    this.commonService.hideOtherPopups("header-component");
    this.showNotifications=!this.showNotifications;
    if(this.showNotifications){
      this.notificationScrolled();
    }
  }

  openCloseSettings(){
    this.commonService.hideOtherPopups("header-component");
    this.showSettings = !this.showSettings
  }

  fetchUserProfile = () => {
    this._authService
      .fetchUserProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res['status'] == 'success') {
            this.userSettingData.isDesktopNotificationAllowed = res['data'][0].isDesktopNotificationAllowed
            this.userSettingData.isEmailNotificationAllowed = res['data'][0].isEmailNotificationAllowed
            if(res['data'][0].photo){
              let photo = this.uploadFileService.getPreSignedURL(
                res['data'][0].photo.substr(res['data'][0].photo.indexOf('.com/') + 5)
              );
              this.commonService.profileImageUrl$.next(photo);
            }else{
              this.commonService.profileImageUrl$.next('');
            }
            UtilsHelper.setObject("orgName", res['data'][0].orgName);
            UtilsHelper.setObject("userEmail", res['data'][0].email);
          }
          //this.loader.stop();
        },
        (error) => {
          //this.loader.stop();
        }
      );
  };

  userSettingChanged(event, isDesktopNotify) {
    if(isDesktopNotify){
      this.userSettingData.isDesktopNotificationAllowed = event
    }else{
      this.userSettingData.isEmailNotificationAllowed = event
    }
    this._authService
        .updateUserProfile({
          isDesktopNotificationAllowed: this.userSettingData.isDesktopNotificationAllowed,
          isEmailNotificationAllowed: this.userSettingData.isEmailNotificationAllowed
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res['status'] == 'success') {
              this._toastr.showSuccess(res['message']);
            }
          },
          (err) => {
          }
        );
  }

  changeNotificationSettings(){
    this._authService
        .updateUserProfile({})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res['status'] == 'success') {
              this._toastr.showSuccess(res['message']);
              // this.activeModal.close(true);
            }
          },
          (err) => {
          }
        );
  }

  openChangePasswordDialog() {
    this.showSettings = false;
    const modelRef = this.modelService.open(ChangePasswordModalComponent, {
      windowClass: 'modal-dialog-centered',
    });
    modelRef.result.then((result) => {});
  }

  myProfile() {
    this.userMenuOpen = false;
    const modelRef = this.modelService.open(UserProfileModalComponent, {
      windowClass: 'modal-dialog-centered',
    });
  }

  // @HostListener('click', ['$event.target'])
  // onClick(e) {
  //   //for settings...
  //   var container = document.getElementById('setting-contain');
  //   var img = document.getElementById('setting-icon');
  //   if (!container.contains(e) && !img.contains(e)) {
  //     this.showSettings = false;
  //   }

  //   //for notifications...
  //   container = document.getElementById('notify-contain');
  //   img = document.getElementById('notify-icon');
  //   if (!container.contains(e) && !img.contains(e)) {
  //     this.showNotifications = false;
  //   }

  //   //for profile...
  //   container = document.getElementById('actions-contain');
  //   img = document.getElementById('actions-icon');
  //   if (!container.contains(e) && !img.contains(e)) {
  //     this.userMenuOpen = false;
  //   }
  // }

  notificationScrolled(){
    let list = [];
    const container = document.getElementById('notification-list1') as any;
    if (this.notificationsList && this.notificationsList.length){
      for(let notification of this.notificationsList){
        const rect = document.getElementById(notification.msgId)?.getBoundingClientRect();
        const topShown = rect?.top >= 0;
        const bottomShown = rect?.bottom <= window.innerHeight;
        if(topShown && bottomShown && !notification.isSeen){
          list.push({msgId: notification.msgId, isSeen: true});
          notification.isSeen = true;
        }
      }
    }
    if(list && list.length){
      this._chatService.markNotificationAsSeen(list).pipe(takeUntil(this.unsubscribe$)).subscribe(
        res=>{
          this.newNotifications = this.notificationsList.filter(f => !f.isSeen).length;
        }, err=>{

        }
      )
    }
  }

  markAsRead(index:number){
    console.log(index);
  }

  deleteNotify(index: number) {}

  openWhatsNewPopup() {
    this.openWhatsNew = !this.openWhatsNew;
}
}