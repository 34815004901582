import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { ChangePasswordModal, LoginModal, ResetPasswordModal } from '@models/auth';
import { BehaviorSubject } from "rxjs";
import { UtilsHelper } from "../utils.helper";
import { CommonService } from './common.service';

let headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isBack$ = new BehaviorSubject<string>("");

  constructor(
    private httpClient: HttpClient
    , private commonService: CommonService
    , private router: Router) { }

  loginUserPost(data: LoginModal) {
    return this.httpClient.post(this.commonService.envUrl() + 'admin/login', data);
  }

  fetchAppToken() {
    let headers = new HttpHeaders({ 'appId': 'HwE6cLfxim', 'clientId': '651170364032', 'clientSecert': 'dxQUkGvoOFgi0i' });
    return this.httpClient.get(this.commonService.envUrl() + 'auth/generate.auth.code', { headers: headers });
  }

  validateToken(token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(this.commonService.envUrl() + 'admin/validate.token', { headers: headers });
  }

  reisterNewUserPost(data, token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.post(this.commonService.envUrl() + 'auth/register', data, { headers: headers });
  }

  reisterNewUserVerifyEmail(url, token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(url, { headers: headers });
  }

  forgotPasswordPost(email) {
    return this.httpClient.post(this.commonService.envUrl() + 'password/forgot', email);
  }

  resetPasswordPost(data: ResetPasswordModal) {
    return this.httpClient.post(this.commonService.envUrl() + 'password/reset', data);
  }

  changePasswordPost(data: ChangePasswordModal) {
    return this.httpClient.post(this.commonService.envUrl() + `password/change`, data);
  }

  fetchUserProfile() {
    return this.httpClient.get(this.commonService.envUrl() + 'user/profile');
  }

  updateUserProfile(data) {
    return this.httpClient.put(this.commonService.envUrl() + 'user/profile', data);
  }

  refreshToken() {
    return this.httpClient.get(this.commonService.envUrl() + 'admin/refresh.token');
  }

  verifyRegistration() {
    return this.httpClient.put(this.commonService.envUrl() + 'user/verify', { isEmailVerified: true });
  }

  logoutAPI() {
    return this.httpClient.get(this.commonService.envUrl() + 'user/logout');
  }

  //Org
  getOrganizations(size, page, search, searchBy) {
    return this.httpClient.get(this.commonService.envUrl() + 'admin/get.orgs?size=' + size + '&page=' + page + '&search=' + search + '&search_by=' + searchBy);
  }
  
  deleteOrganizations(orgId) {
    return this.httpClient.delete(this.commonService.envUrl() + 'admin/delete.org?orgId=' + orgId);
  }

  //Users
  getUsers(size, page, search) {
    let org_id = UtilsHelper.getObject('organization_id');
    if (org_id) {
      let search_by = UtilsHelper.getObject('organization_user_search_by');
      let searchBy = '';
      if (search_by) {
        searchBy = '&search_by=' + search_by;
      }
      return this.httpClient.get(this.commonService.envUrl() + 'admin/org.users?size=' + size + '&page=' + page + '&orgId=' + org_id + '&search=' + search + searchBy);
    }
    if (UtilsHelper.IsSuperAdmin) {
      this.router.navigate(['auth/organizations']);
    }

    return this.httpClient.get(this.commonService.envUrl() + 'admin/users?size=' + size + '&page=' + page + '&search=' + search);
  }

  removeUser(email) {
    if (UtilsHelper.IsSuperAdmin) {
      let org_id = UtilsHelper.getObject('organization_id');
      return this.httpClient.delete(this.commonService.envUrl() + 'admin/user?email=' + email + '&org_id=' + org_id);
    }
    return this.httpClient.delete(this.commonService.envUrl() + 'admin/user?email=' + email);
  }

  // Roles
  getGeneralRoles() {
    return this.httpClient.get(this.commonService.envUrl() + 'role');
  }

  getRoles() {
    let is_parent_org = UtilsHelper.getObject('organization_is_parent');
    if (!is_parent_org) {
      is_parent_org = false;
    }
    return this.httpClient.get(this.commonService.envUrl() + 'admin/roles?is_parent_org=' + is_parent_org);
  }

  updateRole(body) {
    return this.httpClient.put(this.commonService.envUrl() + 'admin/update.role', body);
  }

  removeRole(email, role_id) {
    return this.httpClient.delete(this.commonService.envUrl() + 'admin/remove.role?email=' + email + '&role_id=' + role_id);
  }

  getActiveRoles() {
    let is_parent_org = UtilsHelper.getObject('organization_is_parent');
    if (!is_parent_org) {
      is_parent_org = false;
    }
    return this.httpClient.get(this.commonService.envUrl() + 'admin/active.roles?is_parent_org=' + is_parent_org);
  }

  updateActiveRole(body, user_id, org_id) {
    return this.httpClient.put(this.commonService.envUrl() + 'admin/active.roles?org_id=' + org_id + '&user_id=' + user_id, body);
  }

  //Country Codes
  getCountryCode() {
    return this.httpClient.get('./assets/jsonData/country_phone_code.json')
  }

  // Invoice
  getInvoiceStatistics() {
    let url = this.commonService.envUrl() + 'partner/vendor_order_invoices/stats';
    return this.httpClient.get(url);
  }

  getInvoiceList(string, start_date, end_date, page, size) {
    let url = this.commonService.envUrl() + '/partner/vendor_order_invoices?search=' + string + '&start_date=' + start_date + '&end_date=' + end_date + '&page=' + page + '&size=' + size;
    return this.httpClient.get(url)
  }

  //bids
  getBids(page, size, search_String){
    let url = this.commonService.envUrl() + '/bids/bidsList?size=' + size + '&page=' + page + '&search=' + search_String;
    return this.httpClient.get(url)
  }

  getBidDetails(size, page, search) {
    let org_id = UtilsHelper.getObject('organization_id');
    if (org_id) {
      let search_by = UtilsHelper.getObject('organization_user_search_by');
      let searchBy = '';
      if (search_by) {
        searchBy = '&search_by=' + search_by;
      }
      return this.httpClient.get(this.commonService.envUrl() + 'admin/org.users?size=' + size + '&page=' + page + '&orgId=' + org_id + '&search=' + search + searchBy);
    }
    if (UtilsHelper.IsSuperAdmin) {
      this.router.navigate(['auth/organizations']);
    }

    return this.httpClient.get(this.commonService.envUrl() + 'admin/users?size=' + size + '&page=' + page + '&search=' + search);
  }

  // Partner Orders
  getActivePartnerOrders(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/active_pos?page=' + page + '&search=' + search_String);
  }

  getPendingPartnerOrders(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/pending_pos?page=' + page + '&search=' + search_String);
  }

  getPastPartnerOrders(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/past_pos?page=' + page + '&search=' + search_String);
  }

  // Enquiry
  getLivePartnerEnquiry(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/vendor_orders/live_enquiries?page=' + page + '&search=' + search_String);
  }

  getPastPartnerEnquiry(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/vendor_orders/past_enquiries?page=' + page + '&search=' + search_String);
  }

  getRfqPartnerEnquiry(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'partner/vendor_orders/rfq_list?page=' + page + '&search=' + search_String);
  }

  // Quotes
  getActiveCustomerQuotes(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'quotation/active_quotes?page=' + page + '&search=' + search_String);
  }

  getPendingCustomerQuotes(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'quotation/pending_quotes?page=' + page + '&search=' + search_String);
  }

  getPastCustomerQuotes(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'quotation/past_quotes?page=' + page + '&search=' + search_String);
  }

  // Customer Orders
  getActiveCustomerOrderList(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'orders/active_orders?page=' + page + '&search=' + search_String);
  }

  getPastCustomerOrderList(page, search_String) {
    return this.httpClient.get(this.commonService.envUrl() + 'orders/past_orders?page=' + page + '&search=' + search_String);
  }

  
}