import { Component, OnInit } from '@angular/core';
import { UtilsHelper } from 'src/app/shared/utils.helper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }
  public showHeader: boolean = true;

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }

  ngOnInit(): void {
    //read url params
    let showHeader: any = UtilsHelper.getObject('showHeader');
    // let showHeader:any = this.getQueryVariable("showHeader");
    if (showHeader != null) {
      if (showHeader && showHeader.toString() == "true") {
        showHeader = true;
      }
      else if (!showHeader && showHeader.toString() == "false") {
        showHeader = false
      }
    }
    else {
      showHeader = true;
    }
    this.showHeader = showHeader;
  }

}