<script src="https://www.WebRTC-Experiment.com/RecordRTC.js"></script>

<!-- use 5.6.2 or any other version on cdnjs -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/RecordRTC/5.6.2/RecordRTC.js"></script>

<!-- NPM i.e. "npm install recordrtc" -->
<script src="node_modules/recordrtc/RecordRTC.js"></script>

<!-- bower -->
<script src="bower_components/recordrtc/RecordRTC.js"></script>

<div class="clearfix" *ngIf="!bLoggedIn">
  <div class="clearfix clsMainPage clsToogleOpen">
    <div class="clsTopHeader">
      <button type="button" class="btn btn-primary" id="toogleBtn">
        <i class="fa fa-bars" aria-hidden="true"></i>
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>

      <app-header></app-header>
    </div>

    <div class="clsLeftNav slideInLeft">
      <app-layout *ngIf="!bLoggedIn"></app-layout>
    </div>

    <div class="clsRightSide">
      <div class="clearfix clsScrollContent">
        <router-outlet></router-outlet>
        <!-- <app-executive-dashboard></app-executive-dashboard> -->
      </div>
    </div>
  </div>
</div>
<div class="clearfix" *ngIf="bLoggedIn">
  <div class="clearfix">
    <div class="clsRightSide">
      <div class="clearfix clsScrollContent">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>