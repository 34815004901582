export class UserModel {
  userID: string
  status: string
  updatedAt: string
  name: string
}

export class UserSettings{
  isEmailNotificationAllowed: boolean;
  isDesktopNotificationAllowed: boolean;
  photo: string;
}
