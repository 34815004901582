<div class="clearfix clsLeftBlock clsLeftNav">
  <div class="clsMenuList">
      <ul class="clearfix unstyled clsLeftMenu sidemenucustom">
          
        <!-- <li *ngIf="customerOption || partnerOption">
            <a [routerLink]="['/dashboard/insight']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                <img src="./../../assets/img/orders_2.svg" alt="Machines" />
                <span> Dashboard </span>
            </a>
        </li> -->
          <li *ngIf="!adminOption">
              <a [routerLink]="['/dashboard/users']"  [routerLinkActive]="['active']" class="clearfix">
                  <img src="./../../assets/img/users.svg" alt="Home" />
                  <span> Users </span>
              </a>
          </li>
          
          <li *ngIf="adminOption">
              <a  [routerLink]="['dashboard/organizations']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/Organization.svg" alt="Machines" />
                  <span> Organization </span>
              </a>
          </li>
          
          <li *ngIf="adminOption">
              <a  [routerLink]="['/bids/bids-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/Bids.svg" alt="Machines" />
                  <span> Bids </span>
              </a>
          </li>
          
          <li *ngIf="partnerOption">
              <a  [routerLink]="['/invoice/invoice-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/invoice.svg" alt="Machines" />
                  <span> Invoice </span>
              </a>
          </li>
          
          <li *ngIf="partnerOption">
              <a [routerLink]="['/orders/orders-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/orders_2.svg" alt="Machines" />
                  <span> Orders </span>
              </a>
          </li>
          
          <li>
              <a [routerLink]="['/enquiry/enquiry-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/enquires.svg" alt="Machines" />
                  <span> Enquiry </span>
              </a>
          </li>
          
          <li>
              <a [routerLink]="['/quotation/quotation-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/Quotation.svg" alt="Machines" />
                  <span> Quotation </span>
              </a>
          </li>
          
          <li *ngIf="customerOption">
              <a [routerLink]="['/customer/customer-order-list']" [routerLinkActive]="['active']" routerLinkActive="active-link" class="clearfix">
                  <img src="./../../assets/img/orders_2.svg" alt="Machines" />
                  <span> Orders </span>
              </a>
          </li>
          
          <!-- <li class="dropdown">
              <a  [routerLinkActive]="['active']" class="clearfix" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="./../../assets/img/home.svg" alt="Insights" />
                  <span> Production Planner </span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" [routerLink]="['/jop-scheduler']">Job Scheduler</a>
                      <a class="dropdown-item" [routerLink]="['/orders']">Orders</a> 
                  </div>               
              </a>
          </li> -->
      </ul>
  </div>
</div>
