import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'truventor-user-managment';
  bLoggedIn: boolean = true;
  error: boolean = false;

  constructor(private router: Router){
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.bLoggedIn = event.url.includes('login') || event.urlAfterRedirects.includes('login');
      }
    });
  }
}
