import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  active:number = 0;
  customerOption = false;
  partnerOption = false;
  adminOption = false;
  role;
  constructor() { }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('role'));
    if(this.role == 'Customer'){
      this.customerOption = true;
    } else if(this.role == 'Partner'){
      this.partnerOption = true;
    } else{
      this.adminOption = true;
    }
  }

  expandCollaps(index:number){
    if(this.active==index){
      this.active = 0;
    }else{
      this.active = index;
    }
  }

}
