import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastDisplay } from '../toastr/toast-service';
import { UtilsHelper } from '../utils.helper';

@Injectable({
  providedIn: 'root',
})
export class DashboardAuthGuard implements CanActivate {
  constructor(private _router: Router, private toastr: ToastDisplay) {}

  canActivate(): boolean {
    if (UtilsHelper.IsLoggedIn) {
      return true;
    }
    if (!UtilsHelper.getObject('logout')) {
      this.toastr.showError('You are not logged in');
    }
    this._router.navigate(['auth/login']);
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (UtilsHelper.IsLoggedIn) {
      return true;
    }

    if (!UtilsHelper.getObject('logout')) {
      this.toastr.showError('You are not logged in');
    }
    this._router.navigate(['auth/login']);
  }
}
